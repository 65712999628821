.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 55%;
    margin: 0 auto;
    max-width: 65%;
}
.modal h1, 
.modal h2, 
.modal h3, 
.modal h4, 
.modal h5, 
.modal h6 {
    font-family: "Figtree-Bold";
}
.modal h1 {
    font-size: 35px;
}
.modal h2 {
    font-size: 18px;
}
.modal hr {
    border: 1px solid black;
    justify-self: center;
    margin: 4px auto;
}
.modal .hr-thick {
    border: 2px solid black;
}
.modal a {
    color: var(--grey);
    text-decoration: underline;
}
.modal .flex-container {
    display: flex; 
    justify-content: center;
    align-items: center;
}
.modal .flex-sb {
    display: flex; 
    justify-content: space-between;
}
.modal-wrapper {
    border-radius: 6px;
    background-color:var(--white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24;
    text-align: left;
    overflow-y: scroll;
    max-height: 95%;
}
.modal-content {
    margin: 24px 18px;
    overflow-wrap: break-word; 
}
::-webkit-scrollbar {
    display: none;
}
.content-wrapper {
    border: 4px solid var(--black);
    border-radius: 0 0 6px 6px;
}
.modal .modal-title {
    width: 100%;
    height: 80px;
    background-color: var(--black);
    color: var(--white);
    text-align: center;
    padding-top: 0;
}

.modal .modal-header {
    text-align: left;
    padding: 0;
}

.modal .modal-wrapper .icon-wrapper {
    background-color: var(--white);
    border-radius: 6px 6px 0 0;
    color: var(--black);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.modal .modal-wrapper .icon-wrapper .close-icon {
    cursor: pointer; 
    width: 1.5rem;
    padding: 2px;
    height: auto;
}

/* Mobile */
@media screen and (max-width: 767px) {
    .modal {
        width: 95%;
        max-width: 95%;
    }

    .modal-wrapper {
        width: inherit;
    }
}
@media screen and (max-width: 400px) {
    .modal-content {
        margin: 24px 18px;
    }
}