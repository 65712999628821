/*
* SignupForm.js component styling
*/

.signup-container {
  text-align: center;
}
.signup-container .mountain-home-img-wrapper {
  background: url("../images/mountain-home-temp-bg.png") no-repeat top center;
  background-size: cover;
  height: 460px;
}

.signup-container .youre-in-title {
  font-size: 22px;
  margin: 20px auto;
  width: 35%;
}

.signup-container .fiber-logo {
  width: 35%;
  margin-top: 24px;
}

.signup-container .signup-subtitle {
  margin: 4px 0 48px 0;
  font-size: 1.125rem;
}

.signup-form-wrapper {
  width: 450px;
  margin: 0px auto 50px auto;
  position: relative;
}

.signup-form-wrapper .create-account-title {
  font-size: 26px;
  text-align: left;
  font-family: "Figtree-Bold";
  margin: 20px 0 20px;
}
.note {
  margin: 0 auto;
  color: var(--grey);
}

/* Mobile */
@media screen and (max-width: 767px) {
  .signup-form-wrapper {
    width: 90%;
  }

  .signup-container .fiber-logo {
    width: 85%;
  }
  .user-info {
    padding: 0 10px;
  }

  .signup-container .signup-subtitle {
    margin: 0 0 28px 0;
    font-size: 1.125rem;
  }

  .signup-container .yeah-girl-img-wrapper {
    background: url("../images/YEAH-Girl-Mobile.jpg") no-repeat top center;
    background-size: cover;
    height: 250px;
  }

  .signup-container .youre-in-img {
    width: 150px;
  }
  .signup-container .youre-in-title {
    font-size: 18px;
    width: 90%;
  }

.signup-form-wrapper .create-account-title {
  margin-bottom: 10px;
}
  .signup-form-wrapper form {
    margin: 0;
    padding: 0;
  }
}
