.app-bar .boingo-logo {
    margin: 0;
    width: 100px;
    cursor: pointer;
}

.fiber-welcome-container p {
    font-size:16px;
    line-height:1.1em;
}

.footContent1 {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 13px;
    /* line-height:1.1em; */
}

.footContent1 div {
    margin-right: 20px;
}

#welcome-header .sub-header {
    display: none;
}

#welcome-header header.App-header {
    background-color:transparent;
    padding:0;
}

.welcome-to {
    margin:0 auto;
    text-align:center;
    font-family:"Figtree-Regular";
    padding-top: 0px;
    font-size: 28px;
}

.my-account-link {
    color: var(--bluelink);
    cursor: pointer;
    border: 1px solid #0c93b3;
    border-radius: 8px;
    padding: 6px 24px;
    text-decoration: none; 
}
.my-account-link:hover {
    opacity: 80%;
}

.wrapper.welcome {
    display:flex;
    flex-flow:column wrap;
    align-items: center;
    margin-top: 15px;
    margin-bottom:10px;
    padding: 26px 0;
    background-color: var(--lightblue);
}
.wrapper.welcome h5 {
    font-size: 18px;
}
.welcome-subhead-svg {
    display:inline-flex;
    width: 100%;
    justify-content:center;
    flex-flow:column wrap;
    align-items:center;
}
.welcome-subhead-svg .welcome-subhead-svg-img {
    scale: 0.8;
}
.welcome-subhead-svg svg {
    padding: 0;
    margin: 20px 0;
    width: 350px;
    max-height: 50px;
}

.welcome-subhead-svg.here-to-help svg {
    margin:20px 0 25px 0;
}

.what-youll-need-container {
    display: block;
    justify-items: center;
    width:1024px;
    padding: 15px 0;
    margin: 0 auto;
}

.go-wireless-devices-wrapper {
    flex-basis: 1024px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 auto;
}

.what-youll-need-wrapper {
    width: 700px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 4px auto;
}
.check {
    width: auto;
    max-width: 20px;
    height: 100%;
    margin: 0 6px;
}
.setup-in-seconds-container {
    background-color: var(--lightyellow);
    display: flex;
    flex-basis: 100%;
    justify-content: center;
}

.setup-in-seconds-wrapper {
    flex-basis: 1000px;
}
.welcome-router-section {
    padding: 10px 0 0 0;
}
.welcome-router-section .router-desc {
    color: var(--black);
    text-align: center;
    padding: 0;
    margin: 14px 0;
    width: 100%;
}
.welcome-router-section .router-header-img {
    width: 100%;
    display: flex;
    justify-content: center;;
    margin: 0 auto;
    padding: 24px 0 18px 0;
}
.welcome-router-section .router-header-img > img {
    height: 40px;
}

.welcome-router-section .router-img-section {
    width: 600px;
    height: auto;
    margin: 0 auto;
  }

.welcome-router-section .steps-container {
	margin-top: 50px;
	display: flex;
	flex-flow: row;
	align-items: flex-start;
	justify-content: center;
	align-self: center;
	position: relative;
    
    min-width: 1100px;
    padding-bottom: 10px;
}

.welcome-router-section .steps-container .step {
	display: flex;
    justify-content: center;
	height: 100%;
	width: 33%;
    margin: 0 12px 0 0;
}

.welcome-router-section .steps-container .step .step-img {
	width: 46px;
	height: 46px;
    margin-top: 15px;
}

.welcome-router-section .steps-container .step .step-img img {
	width: 20%;
	height: 100%;
}

.welcome-router-section .steps-container .step .step-content {
	max-width: 80%;
    margin-left: 12px;
	text-align: left;
    font-size: 16px;
    color: var(--black);
    line-height: 1.3em;
}

.here-to-help-wrapper {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    width: 100%;
}

.here-to-help-img {
    margin-top: 25px;
    width: 633px;
    height: 422px;
    align-self: center;
    background-size: contain;
    background-image: url('../images/Military-Gamers-Photo.png');
    background-repeat: no-repeat;
}
.helpSubhead1 {
    display: inline-block;
    margin: 4px 0 14px 0;
}

.helpSubhead2 {
    display: none;
}

.here-to-help-wrapper .welcome-faq-button {
    width: 475px;
    height: 55px;
    align-self: center;
}

.welcome-faq-button a {
    all: unset;
}

.gw-item {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.gw-item.additional {
    padding:0px;
}

.gw-item p {
    text-align: center;
}

.gw-item.what-youll-need svg {
    width: 100%;
    margin-bottom: 12px;
}

.gw-item.wifi svg {
    width: 65px;
}

.gw-item.ethernet svg {
    width: 81px;
}

.gw-item div {
    padding: 5px 0;
    font-family: "Figtree-Bold";
}

.gw-item.devices {
    padding: 0;
    margin: 5px;
    flex-basis: 18%;
    font-family: "Figtree-Bold";
}

.device {
    min-width: 200px;
    min-height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.tplink {
    background-image: url('../images/TP-Link-Deco-W3600.png');
}

.eero {
    background-image: url('../images/Amazon-Eero-6.png');
    margin-right: 0;
}

.dlink {
    background-image: url('../images/D-Link-AX1500.png');
    margin-left: 0;
}

.googleac {
    background-image: url('../images/Google-AC1200.png');
}

.device-sticker1 {
    display: inline;
    flex-basis: 12%;
}

.device-sticker2 {
    display: none;
}

.welcome-footer {
    display: flex;
    flex-flow: column wrap;
    padding: 70px 20px 90px 20px;
    align-items: center;
    font-size:13px;
}

.welcome-footer img {
width: 110px;
margin-bottom:10px;
padding: 10px 0;
}
  
/* Mobile */
@media screen and (max-width: 767px) {
    .mobile-nav .boingo-logo {
        width: 55px;
    }
    .mobile-nav-container {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid var(--grey);
        display: flex;
        align-items: center;
    }
    .welcome-router-section {
        margin-top: 0px;
        padding-left: 24px 10px;
    }
    .welcome-router-section .router-desc {
        margin-top: 30px;
    }
    .welcome-router-section .router-header-img {
        margin: 20px auto;
        scale: 0.9;
    }
    .here-to-help-container {
        margin-top: 20px;
    }
    .welcome-router-section .steps-container {
		height: auto;
        width: 100%;
        min-width: 100%;;
		flex-flow: column;
		gap: 10px;
        font-size: 14px;
        line-height: 1em;
        margin: 10px 0 0 0;
        padding-bottom: 35px;
	}
    
    .welcome-router-section .steps-container .step {
        text-align: left;
        width: 95%;
        margin: 0;
        padding: 0;
    }

    .welcome-router-section .router-img-section {
        width: 100%;
        scale: 0.6;
    }
    .welcome-router-section .steps-container .step .step-img {
        max-width: 33%;
        height: 46px;
    }
    
    .welcome-router-section .steps-container .step .step-content {
        max-width: 90%;
        width: 66%;
        text-align: left;
        font-size: 1.15em;
        color: var(--black);
        margin-left: 20px;
        padding: 0;
    }   

    .mobile-nav-sub-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 40px auto;
        padding: 0 20px;
    }
    .my-account-link {
        color: var(--bluelink);
        cursor: pointer;
        font-size: .95rem;
    }

    .fiber-welcome-container p {
        padding:0 25px;
        margin-bottom: 0;
        text-align:center;
        line-height:1.1em;
    }

    .welcome-footer img {
        margin-bottom: 4px;
    }

    .footContent1 {
        flex-flow: column wrap;
        text-align:center;
        line-height:1em;
    }

    .footContent1 div {
        margin: 5px 0;
    }

    .container.welcome {
        width: 100%;
    }

    .what-youll-need-container {
        width: 100%;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .device {
        min-width: 200px;
        min-height: 135px;
    }

    .gw-item div {
        font-size: 15px;
    }

    .here-to-help-img {
        margin: 0;
        background-position: center;
        background-size: contain;
        background-clip: content-box;
        width: 88%;
        height: 265px;
    }

    .welcome-subhead-svg svg {
        max-height: 32px;
    }
    
    .helpSubhead2 {
        width: auto;
        display: block;
        padding: 0 30px;
        text-align:center;
    }

    .device-sticker1 {
        display: none;
    }

    .device-sticker2 {
        display: inline-flex;
        height: 65px;
        flex-basis: 100%;
        margin-top: 10px;
        justify-content: center;
        z-index: 10;
    }

    .device-sticker1 svg,
    .device-sticker2 svg {
        width: auto;
        height: 110px;
    }

    .wrapper.welcome {
        width: auto;
        margin: 0;
        padding: 0;
        text-align:center;
    }

    #welcome-header header.App-header {
        padding-top:0;
    }

    #welcome-header header.App-header .fiberLogo {
        height:50px;
        max-width:85%;
    }

    .welcome-to {
        text-align:"center";
        font-family:"Figtree-Regular";
        font-size: 22px;
        padding-top: 0px;
    }

    .wrapper.welcome h5 {
        line-height:1em;
        margin-bottom:7px;
        font-size:20px;
        padding: 0 18px;
    }

    .container .wrapper.welcome {
        padding: 20px;
        margin-top:18px;
    }

    .what-youll-need-wrapper {
        width: 100%;
        margin: 10px auto;
    }
    .what-youll-need-wrapper .what-youll-need-images {
        flex-direction: column;
    }
    .gw-item {
        justify-content:flex-start;
        text-align:center;
    }

    .gw-item.additional {
        width: 90%;
        font-size: 16px;
        margin: 0 auto;
        padding-bottom: 14px;
    }

    .gw-item.what-youll-need svg {
        width: 190px;
        margin-bottom: 12px;
    }

    .gw-item svg {
        max-height: 90px;
        margin-top: 0;
    }
    .router-images {
        flex-direction: column;
    }
    .here-to-help-wrapper{
        padding: 0;
    }
    .here-to-help-wrapper .welcome-faq-button {
        width: 85%;
        height: 45px;
        margin-top: 0;
    }
    .helpSubhead1 {
        margin-bottom: 20px;
    }
    
}