.app-bar {
  box-shadow: none !important;
}

.app-bar .boingo-logo {
  margin: 0;
  width: 100px;
}
.app-bar .fiber-logo {
  margin-left: 0px;
  width: 300px;
}

.mountain-bike-img {
  background: url("../images/mountain-home-temp-bg.png") no-repeat top center;
  background-size: cover;
  height: 60vh;
}

.login-link {
  color: var(--bluelink);
  cursor: pointer;
  text-decoration: underline; 
}

.avail-btn {
  background: var(--blue);
  color: var(--white);
  padding: 7px 20px;
  border-radius: 7px;
  font-family: "Figtree-Bold";
  position: relative;
  font-size: 18px;
  text-wrap: nowrap;
  transition: 0.3s all;
}
.avail-btn.large {
  font-size: 20px;
  padding: 15px 55px;
  position: relative;
}

.avail-btn:hover {
  background-color: var(--darkblue);
}

.hero-section .hero-desc {
  color: var(--white);
  text-align: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 40px;
  font-size: 40px;
  font-family: "Figtree-Bold";
}

.magnify-lens {
  width: 65px;
  position: absolute;
  top: 8px;
  right: 15px;
}

.plan-section {
  padding: 50px 0 100px;
  background-color: #e0edf5;
  position: relative;
}
.plan-section .title {
  font-family: "Figtree-Bold";
  font-size: 22px;
  color: var(--black);
  width: 55%;
  margin: auto;
  line-height: 1.2em;
}
.plan-section .sub-title {
  font-size: 16px;
  width: 60%;
  text-align: center;
  margin: 20px auto;
  color: var(--black);
}
.feature-section .plan-container {
  margin: auto;
  width: 100%;
  margin-top: -70px;
  text-align: center;
}
.feature-section .plan-wrap {
  background: url("../images/Plan-Box-Collapsed-Desktop.svg") no-repeat;
  background-size: 100%;
  height: 300px;
  width: 400px;
  margin: 0 20px;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: url("../images/Plan-Box-Expanded-Desktop.svg") no-repeat;
  background-size: 100%;
  height: 480px;
}

.plan-wrap .plan-title {
  color: var(--red);
  font-size: 38px;
  font-family: "Figtree-Bold";
  margin-top: 10px;
  text-align: center;
}

.plan-wrap.two-gigabits .plan-title {
  color: var(--blue);
}
.plan-wrap .description-wrap {
  text-align: center;
  margin-top: 20px;
}
.plan-wrap .desc-row {
  position: relative;
}

.plan-wrap .coming-soon-ribbon {
  background: url("../images/coming-soon.svg") no-repeat top right;
  height: 125px;
  width: 300px;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: -1;
}
.plan-wrap .desc-row.left {
  margin-left: 40px;
  width: 50%;
}
.plan-wrap .desc-row.right {
  text-align: right;
}
.plan-wrap .desc-row .price-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.plan-wrap .desc-row .crossed-price {
  margin-top: 14px;
  width: 23%;
  min-width: 94px;
}
.plan-wrap .desc-row .description {
  font-size: 20px;
  line-height: 1.1;
  font-family: "Figtree-ExtraBold";
}
.plan-wrap .desc-row .description .whisker {
  max-width: 16px;
  display: inline-block;
  vertical-align: top;
  margin: 2px;
}
.plan-wrap .desc-row .amzn-desc {
  font-family: "Figtree-ExtraBold";
}

.plan-wrap .desc-row .dollar-sign {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  font-size: 38px;
}
.plan-wrap .desc-row .price {
  font-size: 62px;
  font-family: "Figtree-Bold";
  margin-left: 10px;
  display: inline-block;
}
.plan-wrap .desc-row .price .duration {
  font-size: 24px;
}

.plan-wrap .details-wrap {
  text-align: left;
  color: var(--bluelink);
  margin-left: 35px;
}

.plan-wrap .plan-details-list {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 10px auto;
}

.plan-wrap .plan-details-list li {
  display: inline-flex;
  align-items: top;
  list-style-type: none;
  text-align: left;
  margin: 4px 0;
  padding-left: 0;
}
.plan-wrap .plan-details-list .check {
  width: auto;
  max-width: 20px;
  height: 100%;
  margin: 0 6px;
}
.plan-wrap .up-arrow {
  transform: rotate(180deg);
}
.plan-wrap .get-started-btn {
  background: var(--red);
  color: var(--white);
  padding: 7px 25px;
  font-size: 20px;
  font-family: "Figtree-Bold";
  border-radius: 7px;
  margin: 10px 0 0 40px;
  display: inline-block;
  transition: 0.3s ease-out;
}
.get-started-btn.disabled {
  background: #c9c9c9;
  cursor: default;
}
.get-started-btn:hover {
  background-color: var(--darkred);
}

.why-fiber-container {
  margin-top: 50px;
}
.why-fiber-container > div {
  display: inline-block;
  width: 240px;
  vertical-align: top;
  margin: 0 60px 60px;
}

.why-fiber-container .fiber-icon {
  height: 120px;
  width: 120px;
  margin: auto;
}
.fiber-icon.speed {
  background: url("../images/Speed-Icon.svg") no-repeat center;
  background-size: 60%;
}
.fiber-icon.instant {
  background: url("../images/Instant-On-Icon.svg") no-repeat center;
  background-size: 90%;
}
.fiber-icon.no-equip {
  background: url("../images/No-Equipment-Charges-Icon.svg") no-repeat center;
  background-size: 80%;
}
.fiber-icon.data-caps {
  background: url("../images/No-Data-Caps-Icon.svg") no-repeat center;
  background-size: 80%;
}
.fiber-icon.heart {
  background: url("../images/Customer-Care-Icon.svg") no-repeat center;
  background-size: 85%;
}
.fiber-icon.we-get-you {
  background: url("../images/We-Get-You-Icon.svg") no-repeat center;
  background-size: 65%;
}
.why-fiber-container .why-fiber-title {
  font-family: "Figtree-Bold";
  color: var(--black);
  font-size: 24px;
  text-wrap: nowrap;
  margin-bottom: 5px;
  margin-top: 10px;
}

.why-fiber-container .why-fiber-desc {
  line-height: 1.2;
}

.why-fiber-container .learn-more {
  margin-top: 10px;
  line-height: 1.2;
}

.router-section {
  background-color: #f7f0e6;
  padding: 50px 0;
  margin-top: 40px;
  text-align: center;
}

.router-section .router-header-img {
  margin: auto;
  width: 65%;
}

.router-section .router-desc {
  color: var(--black);
  font-size: 17px;
  margin: 30px auto 30px;
  width: 70%;
  line-height: 1.3;
}

.router-section .router-desc span {
  display: block;
  font-family: "Figtree-Bold";
}

.router-section .router-img-wrapper {
  display: inline-block;
  margin: 0 15px;
}

.router-section .router-img-wrapper img {
  width: 240px;
}

.router-section .router-img-wrapper .router-name {
  font-family: "Figtree-Bold";
  color: var(--black);
}

.router-section .last-paragraph {
  margin: 50px auto;
}
.router-section .router-bottom-section img {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}
.router-section .router-bottom-section .works-with-boingo-img {
  width: 180px;
  margin-left: 200px;
}
.router-section .router-bottom-section .look-for-this {
  width: 150px;
}

.landing-page-footer-section {
  padding: 50px;
}
.landing-page-footer-section .footer-title {
  font-size: 22px;
  color: var(--black);
  margin: 0px auto 20px;
  font-family: "Figtree-Bold";
}

.landing-page-footer-section hr {
  margin: 60px auto;
}
.landing-page-footer-section .horizontal-line {
  margin: 50px auto;
  width: 70%;
  display: block;
}
.landing-page-footer-section .footer-logo {
  width: 130px;
  margin-bottom: 50px;
}
.landing-page-footer-section .footer-links-wrapper {
  display: inline-block;
  font-size: 13px;
  margin: 0 10px;
}

.landing-page-footer-section .footer-links-wrapper .copyright {
  font-size: 13px;
  margin-bottom: 50px;
}

@media screen and (max-width: 899px) {
  .mobile-nav .boingo-logo {
    width: 55px;
  }
  .mobile-nav .fiber-logo {
    margin-left: 0px;
    width: 100%;
  }

  .mobile-nav-container {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid var(--grey);
    display: flex;
    align-items: center;
  }
  .mobile-nav-container img {
    width: 230px;
    padding: 0 35px;
  }
  .mobile-nav-container .close-icon {
    margin-right: 10px;
    display: inline-block;
  }
  .mobile-nav-sub-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
    padding: 0 20px;
  }

  .mobile-nav-sub-section .lets-start {
    text-align: center;
    font-family: "Figtree-Medium";
    color: var(--black);
    margin-bottom: 20px;
    line-height: 1.2;
    font-size: 18px;
  }

  .mobile-nav-hr {
    border-bottom: 1px solid #c9c9c9;
    width: 80%;
    display: block;
    margin: 30px auto;
  }

  .mobile-sign-in-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile-sign-in-wrapper img {
    width: 30px;
    margin-right: 7px;
  }

  .mobile-sign-in-wrapper a {
    font-family: "Figtree-Medium";
    display: inline-flex;
    align-items: center;
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .mobile-nav .boingo-logo {
    width: 55px;
  }
  .mobile-nav .fiber-logo {
    margin-left: 0px;
    width: 60%; 
    min-width: 220px; 
  }

  .mobile-nav-container {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid var(--grey);
    display: flex;
    align-items: center;
  }
  .mobile-nav-container img {
    width: 230px;
    padding: 0 35px;
  }
  .mobile-nav-container .close-icon {
    margin-right: 10px;
    display: inline-block;
  }
  .mobile-nav-sub-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
    padding: 0 20px;
  }

  .mobile-nav-sub-section .lets-start {
    text-align: center;
    font-family: "Figtree-Medium";
    color: var(--black);
    margin-bottom: 20px;
    line-height: 1.2;
    font-size: 18px;
  }

  .mobile-nav-hr {
    border-bottom: 1px solid #c9c9c9;
    width: 80%;
    display: block;
    margin: 30px auto;
  }

  .mobile-sign-in-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile-sign-in-wrapper img {
    width: 30px;
    margin-right: 7px;
  }

  .mobile-sign-in-wrapper a {
    font-family: "Figtree-Medium";
    display: inline-flex;
    align-items: center;
  }

  .mountain-bike-img {
    height: 26vh;
  }
  .hero-section .hero-desc {
    font-size: 36px;
    width: 80%;
    margin-bottom: 14px;
    line-height: 1;
  }
  .hero-section .hero-desc span {
    display: block;
  }

  .avail-btn.large {
    font-size: 16px;
    padding: 10px 25px;
  }
  .magnify-lens {
    width: 45px;
    right: 10px;
  }
  .plan-section {
    padding-bottom: 75px;
  }

  .plan-section .title {
    width: 90%;
    line-height: 1.4;
  }
  .plan-section .sub-title {
    width: inherit;
  }

  .feature-section .plan-container {
    margin-top: -65px;
    height: 450px;
  }
  .feature-section .plan-wrap {
    width: 100%;
    margin: 0;
    background: url("../images/Plan-Box-Expanded-Mobile.svg") no-repeat;
  }

  .feature-section .plan-wrap.no-discount {
    width: 90%;
    margin: 0;
    margin: -14px;
    background: url("../images/Plan-Box-Expanded-Mobile.svg") no-repeat;
  }
  .feature-section .plan-container.no-discount {
    margin-top: -50px;
    width: 95%;
    height: 420px;
  }

  .feature-section .description-wrap {
    margin: 0;
    padding: 0;
  }

  .feature-section .plan-container .plan-title {
    text-align: center;
    font-size: 32px;
    margin: 14px 0;
  }

  .feature-section .plan-container .description {
    font-size: 18px;
  }
  .plan-wrap .desc-row.left {
    vertical-align: top;
    margin: 25px 0 0 25px;
    width: 168px;
  }
  .plan-wrap .desc-row .amzn-desc {
    margin: 8px 0;
    padding: 0 20px;
  }
  .plan-wrap .desc-row .price {
   margin-top: 6px; 
  }
  .plan-wrap .details-wrap {
    margin-left: 20px;
  }

  .plan-wrap .plan-details-list {
    width: 80%;
    margin-left: 45px;
    margin-top: 20px;
  }
  .plan-wrap .plan-details-list.no-discount {
    margin: 20px 40px 20px 35px;
  }
  .plan-wrap .plan-details-list span {
    align-self: center;
  }
  .plan-wrap .get-started-btn {
    margin-left: 25px;
  }
  .plan-wrap .coming-soon-ribbon {
    height: 105px;
    top: -2px;
    right: -2px;
  }
  .why-boingo-fiber-img {
    margin-top: 40px;
    width: 90%;
  }
  .why-boingo-fiber-img.no-discount {
    margin-top: -14px;
  }
  .why-fiber-container {
    margin-top: 40px;
  }
  .why-fiber-container .why-fiber-box {
    display: inline-block;
    width: 145px;
    vertical-align: top;
    margin: 0px 13px 40px;
  }

  .why-fiber-container .why-fiber-title {
    text-wrap: inherit;
    line-height: 1.2;
    font-size: 22px;
  }

  .why-fiber-container .fiber-icon {
    width: 70%;
  }

  .fiber-icon.no-equip {
    background: url("../images/No-Equipment-Charges-Icon-Mobile.svg") no-repeat
      center;
    background-size: 80%;
  }
  .fiber-icon.data-caps {
    background: url("../images/No-Data-Cap-Icon-Mobile.svg") no-repeat center;
    background-size: 80%;
  }

  .router-section .router-header-img img {
    display: none;
  }

  .router-section .router-header-img {
    width: 100%;
    background: url("../images/setup-in-seconds-savings-subhead-mobile.svg")
      no-repeat center;
    height: 75px;
  }

  .router-section .router-desc {
    width: 96%;
  }

  .router-section .router-img-wrapper {
    margin: 0 0 25px 0;
  }

  .router-section .router-img-wrapper img {
    width: 170px;
  }

  .router-section .last-paragraph {
    margin: 30px 0 50px;
  }

  .router-section .router-bottom-section .works-with-boingo-img {
    width: 140px;
    margin-left: 0px;
  }

  .landing-page-footer-section {
    padding: 50px 10px;
  }

  .landing-page-footer-section .footer-title {
    font-size: 20px;
    line-height: 1.2;
  }

  .landing-page-footer-section .horizontal-line {
    width: 100%;
  }
  .landing-page-footer-section .footer-logo {
    width: 100px;
  }
  .landing-page-footer-section .footer-text {
    line-height: 1.3;
    margin-bottom: 4px;
  }

  .fixed-signup-form {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 20px auto;
    background: var(--white);
    border-top: 2px solid var(--black);
    box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.2);
  }

  .fixed-signup-form a {
    background: var(--blue);
    color: var(--white);
    font-family: "Figtree-Bold";
    margin: 10px auto;
    display: block;
    width: 90%;
    padding: 10px 0;
    border-radius: 6px;
    font-size: 18px;
  }
}

/* targets medium size screens */
@media (min-width: 600px) and (max-width: 960px) {
  .feature-section .plan-wrap {
    width: 62%;
  }
}

/* targets xsmall size screens */
@media (max-width: 400px) {
  .feature-section .plan-container {
    margin-top: -65px;
    height: 414px;
  }
  .why-boingo-fiber-img {
    margin-top: 24px;
  }
  .why-boingo-fiber-img.no-discount {
    margin-top: -40px;
  }
  .feature-section .plan-container.no-discount {
    height: 400px; 
  }
  .plan-wrap .plan-details-list {
    line-height: 1; 
    font-size: .95rem;
    margin-left: 40px;
    margin-top: 0px;
  }
}