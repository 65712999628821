.error-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 50vh;
    font-family: "Figtree-Regular", Helvetica, Arial, sans-serif;
}

.error-container p {
    font-size: 16px;
    line-height: 1.5em;
}

.error-container #error-header {
    color: transparent;
    width: 800px;
    margin: 0px auto;
}

.error-container .fiberLogo {
    color: transparent;
    height: 100px;
    margin: 28px 0;
}

.error-container .error-container-description {
    width: 100vw;
    align-items: center;
    background-color: var(--lightblue);
    color: #3D434A;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
    padding: 26px 0;
}
.error-container .error-wrapper {
    padding: 48px 24px;
    font-family: "Figtree-Bold", "Museo-Slab-900", Helvetica, Arial, sans-serif;
}

.error-container .error-subheader {
    color: var(--red);
    font-size: 32px;
    margin: 4px auto;
}

.error-container .error-details {
    color: var(--red);
    margin: 20px auto;
}
.error-container .error-details .error-message {
    font-size: 20px;
    margin: 14px auto;
}

.error-container .home-button {
    height: 50px;
}

/* Mobile Styles */
@media screen and (max-width: 767px) {
    
    .error-container {
        height: 70vh;
    }
    
    .error-container #error-header {
        width: 100vw;
        margin: 0 auto;
    }

    .error-container .fiberLogo {
        height: 100px;
        margin: 0 auto;
        width: 65%;
        min-width: 220px;
    }
    
    .error-container .error-container-description {
        position: absolute;
        top: 110px;
    }
    
    .error-container .error-wrapper {
        padding: 24px;
    }
    
    .error-container .error-details {
        color: var(--red);
    }

}