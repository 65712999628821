* {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Figtree-Regular", Arial, Helvetica, sans-serif;
}

:root {
  --blue: #35c0e2;
  --darkblue: #2fa9c7;
  --bluelink: #0c93b3;
  --red: #d52b1e;
  --darkred: #b4261e;
  --grey: #3d434a;
  --greyBg: #2e3138;
  --lightgrey: #d3d3d3;
  --mediumgrey: #9c9c9c;
  --white: #fff;
  --black: #000;
  --lightblue: #e0edf5;
  --darkred: #b4261e;
  --lightyellow: #faefe5;
  --sand: #f7f0e6;

  touch-action: pan-x pan-y;
  height: 100%;
}

.black {
  color: var(--black);
}

.blue {
  color: var(--blue);
}

a {
  text-decoration: none;
  color: var(--bluelink);
}

/* .Figtree-Bold {
  font-family: "Figtree-Bold";
}

.Figtree-Black {
  font-family: "Figtree-Black";
} */

/* 
a:active {
  color: var(--red);
}

a:visited {
  color: var(--blue);
} */

body {
  font-family: "Figtree-Regular", Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

form {
  max-width: 450px;
  margin: 20px auto 0;
  position: relative;
  width: 100%;
}

h1 {
  font-weight: 100;
  color: var(--white);
  text-align: center;
  padding: 10px 0;
}

.form {
  max-width: 400px;
  margin: 0 auto;
}

p {
  color: var(--grey);
  margin: 15px 0;
}

.error {
  color: var(--red);
}

.body-wrap .input-error {
  font-size: 14px;
}

.input-error {
  color: var(--red);
  margin-top: 0;
  font-size: 12px;
}

.input-error::before,
.error::before,
.milstar-input p.Mui-error:last-child:before,
.form-input p.Mui-error:last-child:before {
  display: inline;
  content: "⚠ ";
  color: var(--red);
}

input,
select,
textarea {
  font-size: 16px;
}

input,
select {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--lightgrey);
  padding: 15px;
  margin-bottom: 10px;
  font-family: "Figtree-Regular";
}

input::placeholder {
  color: var(--mediumgrey);
}

input:focus,
select:focus {
  outline: none;
}

select {
  position: absolute;
  left: 0;
  height: 48px;
  color: var(--mediumgrey);
  width: 125px;
  border: 1px solid var(--lightgrey);
  border-bottom: 0px;
  border-radius: 4px 0 0 4px;
  padding-left: 10px;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
  background-color: transparent;
}

button[type="submit"],
input[type="submit"] {
  background: var(--blue);
  color: white;
  border: none;
  /* margin-top: 40px;
  font-size: 24px;
  border-radius: 8px;
  padding: 10px 50px; */
  font-family: "Figtree-Bold", Arial, Helvetica, sans-serif;
}

button[type="button"]:hover,
button[type="submit"]:hover,
input[type="submit"]:hover {
  background: var(--darkblue);
  cursor: pointer;
}

button[type="submit"]:active,
input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
}

input:disabled {
  opacity: 0.2;
}

input[type="button"]:hover {
  transition: 0.3s color;
}

button[type="submit"],
input[type="button"],
input[type="submit"] {
  appearance: none;
}

button[type="button"] {
  display: block;
  appearance: none;
  /* background: var(--red); */
  color: var(--white);
  border: none;
  padding: 10px 20px;
}

hr {
  margin-top: 30px;
}

.App {
  margin: 0 auto;
}

.App-header {
  background: var(--lightblue);
  padding: 30px 0 30px;
}

.fiberLogo {
  margin: auto 0;
  background: url("../images/Boingo-Fiber-Logo-Horizontal.svg") no-repeat center;
  text-align: center;
  height: 118px;
}

.App-header h4 {
  color: var(--grey);
  margin: 14px 0;
  font-size: 30px;
  font-family: "Figtree-Medium";
  font-display: swap;
}

.sub-header {
  font-family: "Figtree-Regular";
  font-size: 32px;
}

.App-header.complete {
  background: url("../images/YEAH-Girl-2.jpg") no-repeat center;
  padding: 0;
  background-size: cover;
  width: 100%;
  height: 600px;
  background-position-y: top;
}

.App-header.complete h4 span {
  display: inline-block;
}

.table-wrapper .product-title {
  font-family: "Figtree-Semibold";
  font-size: 18px;
  color: var(--grey);
}
.table-wrapper .product-title .amount-off {
  color: #868a93;
  text-decoration: line-through;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}

.table-wrapper .description {
  color: var(--mediumgrey);
}

.navbar {
  background-color: var(--grey);
  margin: auto;
  height: 65px;
  clear: both;
}

.navbar .nav-wrapper {
  width: 1024px;
  margin: 0 auto;
}

.navbar img {
  width: 90px;
  margin: 10px 0;
  float: left;
}

.body-wrap p {
  font-size: 18px;
}

.complete p {
  margin-top: 50px;
  font-size: 22px;
}

.youin-wrapper svg {
  margin-top: -75px;
  width: 320px;
}

.container.complete {
  background-color: var(--white);
}

.container .find-out-title {
  font-size: 28px;
  margin: 20px auto;
}

.container .wrapper {
  margin: auto;
  padding-bottom: 30px;
}

.coming-soon svg {
  width: 350px;
  margin: 30px auto;
}

.note {
  font-size: 12px;
  color: var(--grey);
}

.unitSelector {
  position: relative;
  border: 1px solid var(--lightgrey);
  border-radius: 4px;
  width: 100%;
  background-color: var(--white);
}

.note {
  display: block;
  margin: 30px 0 15px;
}

.note a {
  color: var(--grey);
}

.footer {
  width: auto;
  padding: 30px 20px;
}

.footer img {
  width: 150px;
}

.logo-wrapper {
  margin: 100px auto;
  padding-bottom: 50px;
}

.logo-wrapper svg {
  width: 400px;
}

.breadcrumbs {
  margin: 10px auto;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--lightgrey);
}

.breadcrumbs .fiber-plan-link {
  color: var(--mediumgrey);
}

.breadcrumbs .fiber-plan-link.active-link {
  color: var(--red);
}
.breadcrumbs .fiber-plan-link.disabled {
  cursor: default;
}

.breadcrumbs .active {
  color: var(--red);
}

button.pw-icon[type="button"] {
  background: transparent;
  color: var(--mediumgrey);
  width: 20px;
  height: 10px;
}

button.pw-icon[type="button"] svg {
  position: absolute;
  left: 9px;
  top: -3px;
}

/* label[data-shrink="true"].focused {
  color: var(--blue);
} */
label[data-shrink="false"].invalid,
label[data-shrink="true"].invalid {
  color: var(--red);
}

/* label[data-shrink="true"].valid {
  color: var(--mediumgrey);
} */

label[data-shrink="true"] {
  background: var(--white);
  padding: 0 8px 0px 5px;
  color: var(--blue);
}

label[data-shrink="true"] ~ .hosted-field.focused {
  border: 2px solid var(--blue);
}

@media screen and (max-width: 767px) {
  .fiberLogo {
    /* background: url("../images/Boingo-Fiber-Logo-Stacked.svg") no-repeat center; */
    margin: auto;
    width: 90%;
  }
  .App-header {
    padding: inherit;
  }
  .sub-header {
    font-family: "Figtree-Regular";
    font-size: 26px;
    width: 85%;
    margin: 13px auto 0;
  }

  .App-header h4 span {
    display: block;
  }

  .coming-soon svg {
    padding: 0;
    margin: 0;
    width: 285px;
  }

  .container p {
    text-align: left;
    font-size: 16px;
  }

  .container .wrapper {
    padding: 20px;
  }

  .container .find-out-title {
    font-size: 22px;
    text-align: center;
  }
  .note {
    text-align: left;
  }

  .App-header.complete {
    background: url("../images/YEAH-Girl-Mobile.jpg") no-repeat center;
    background-position-y: 0px;
    background-size: cover;
    width: 100%;
    height: 330px;
    background-size: 100%;
  }
  .complete p {
    text-align: center;
  }

  .container.complete {
    padding: 0 20px;
  }

  .youin-wrapper {
    margin: 0;
    padding: 0;
    margin-top: -110px;
  }

  .complete .youin-wrapper svg {
    width: 180px;
  }

  .logo-wrapper {
    margin-top: 60px;
  }

  .logo-wrapper svg {
    width: 90%;
  }

  .navbar .nav-wrapper {
    width: 100%;
    padding-left: 10px;
  }
}
