/* Modal Styling for login and deviceIDFlow */

.deviceModel {
  width: 50px;
  height: 50px;
  background: url("../images/selfcare-icons-all.svg") no-repeat;
  background-size: 350px;
}

.deviceModel.model {
  background-position: 0 0;
}
.deviceModel.Phone {
  background-position: -50px 0px;
}
.deviceModel.Tablet {
  background-position: -98px 0px;
}
.deviceModel.Computer {
  background-position: -148px 0px;
}
.deviceModel.Gaming {
  background-position: -198px 0px;
}
.deviceModel.TV {
  background-position: -246px 0px;
}
.deviceModel.Device {
  background-position: 3px -100px;
  border: 0;
}
